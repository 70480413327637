import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

const LoadMoreButton = ({
  isLoading,
  loadMoreItems,
  label = 'Load More',
}: {
  isLoading: boolean;
  loadMoreItems: () => void;
  label?: string;
}) => (
  <Box
    sx={{
      mt: 1,
      mr: 1,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <LoadingButton
      loading={isLoading}
      variant="outlined"
      onClick={loadMoreItems}
    >
      {label}
    </LoadingButton>
  </Box>
);

export default LoadMoreButton;
