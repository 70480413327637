import { useAutoneTranslation } from '@autone/translations';
import { type CustomTheme, isKeyInType } from '@autone/ui';
import {
  alpha,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { type FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { type APP_NAME, STEPS } from '../../../constants';
import { useCheckStepAccess } from '../../../hooks';
import { useGetBatchQuery } from '../../../redux/services';
import { getBatchIdFromUrl, useGetBuyCurrency } from '../../../utils';

const getBarColor = (index: number, urlIndex: number, theme: CustomTheme) => {
  if (index < urlIndex) {
    return theme.palette.success.dark;
  } else if (index === urlIndex) {
    return `linear-gradient(90deg, ${
      theme.palette.success.dark
    } 50%, ${alpha(theme.palette.action.disabled, 0.4)} 50%)`;
  }

  return undefined;
};

const STEP_SIZE = 24;

const StepProgress: FC = () => {
  const { batchId } = getBatchIdFromUrl();
  const { buyingCurrencyIso } = useGetBuyCurrency();
  const history = useHistory();
  const theme = useTheme<CustomTheme>();
  const { data: batchInfo } = useGetBatchQuery({ batchId }, { skip: !batchId });
  const { t } = useAutoneTranslation<typeof APP_NAME>();

  const { maxActiveStepNumber, redirectUserToLastActiveStep, stepsInHeader } =
    useCheckStepAccess();

  // redirect the user to the last active step on page load
  useEffect(() => {
    if (batchInfo) redirectUserToLastActiveStep();
  }, [redirectUserToLastActiveStep, batchInfo]);

  const handleStepClicked = (
    stepName: string,
    stepNumber: number,
    maxStep: number,
  ) => {
    // do not allow to go to the step if it is greater than the current step
    if (stepNumber > maxStep) return;

    const redirectUrl = isKeyInType(STEPS, stepName) && STEPS[stepName].url;

    if (!redirectUrl) return;

    const redirectUrlEnriched = redirectUrl.replace(':batchId', batchId);

    history.push({
      pathname: redirectUrlEnriched,
      search: `?currencyIso=${buyingCurrencyIso}`,
    });
  };

  const stepItemWidthPx: number = 80;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {stepsInHeader.map((stepName, stepNumber) => {
        const title = t(`header.steps.${stepName}`, {
          defaultValue: undefined,
        });

        const numberOfSteps = stepsInHeader.length;

        let flexAlignment = 'center';
        let stepWidth = stepItemWidthPx as string | number;

        if (stepNumber === 0) {
          flexAlignment = 'start';
          stepWidth = 'fit-content';
        } else if (stepNumber === numberOfSteps - 1) {
          flexAlignment = 'end';
          stepWidth = 'fit-content';
        }

        const barColor = getBarColor(stepNumber, maxActiveStepNumber, theme);
        const dividerWidth = `calc((100% - ${
          stepItemWidthPx * numberOfSteps
        }px) / ${numberOfSteps})`;
        const isActive = stepNumber <= maxActiveStepNumber;

        return (
          <React.Fragment key={title}>
            <Stack
              direction="column"
              gap={1}
              alignItems={flexAlignment}
              onClick={() =>
                handleStepClicked(stepName, stepNumber, maxActiveStepNumber)
              }
              sx={{
                cursor: isActive ? 'pointer' : 'default',
                width: stepWidth,
              }}
            >
              <Box
                sx={{
                  backgroundColor: isActive ? 'success.dark' : 'grey.500',
                  borderRadius: '100%',
                  height: STEP_SIZE,
                  width: STEP_SIZE,
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  textAlign: flexAlignment,
                  color: isActive ? 'text.primary' : 'grey.500',
                }}
              >
                {title}
              </Typography>
            </Stack>
            {stepNumber !== numberOfSteps - 1 && (
              <Divider
                sx={{
                  mt: `${STEP_SIZE / 2}px`,
                  width: dividerWidth,
                  height: 1.5,
                  background: barColor,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default StepProgress;
