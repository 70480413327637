import { type OTB } from '@autone/openapi-buying';
import { useAutoneTranslation } from '@autone/translations';
import { CircularProgress, MenuItem, Select, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';

import { type APP_NAME, INITIAL_PRICE_TYPE } from '../../../constants';
import { useAppDispatch as useDispatch } from '../../../redux/hooks';
import { SET_PRICE_TYPE } from '../../../redux/reducers/priceType';
import useGetPriceTypes from '../../../utils/hooks/useGetPriceTypes';

const PriceTypeDropdown = ({ otb }: { otb?: OTB }) => {
  const dispatch = useDispatch();
  const { priceType, priceTypeOptions } = useGetPriceTypes();
  const { t } = useAutoneTranslation<typeof APP_NAME>();

  useEffect(() => {
    if (otb) dispatch(SET_PRICE_TYPE(otb.price_type));
  }, [otb, dispatch]);

  return (
    <FormControl>
      <Select
        labelId="price-type-dropdown"
        id="price-type-dropdown"
        value={priceType || INITIAL_PRICE_TYPE}
        renderValue={(renderedValue) => {
          if (!priceType || !priceTypeOptions) {
            return (
              <Stack sx={{ width: '30px' }} alignItems="center">
                <CircularProgress size={18} />
              </Stack>
            );
          }

          return <>{t(`price-type.${renderedValue}`)}</>;
        }}
        onChange={(event) => {
          dispatch(SET_PRICE_TYPE(event.target.value));
        }}
      >
        {priceTypeOptions?.map(({ value }) => (
          <MenuItem key={value} value={value} sx={{ display: 'flex', gap: 1 }}>
            {t(`price-type.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PriceTypeDropdown;
