import { type SxProps } from '@mui/material';

import SearchTextField from '../table/components/SearchTextField';

const FilterSearch = ({
  searchInput,
  placeholder,
  handleSearchInputChange,
  handleClearSearchInput,
  filterSearchSx,
}: {
  searchInput: string;
  placeholder?: string;
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearSearchInput: () => void;
  filterSearchSx?: SxProps;
}) => (
  <SearchTextField
    size="small"
    textFieldSx={{
      width: '100%',
    }}
    sx={{ width: '90%', ml: 1, mt: 0.5, ...filterSearchSx }}
    placeholder={placeholder}
    value={searchInput}
    onChange={handleSearchInputChange}
    onClear={handleClearSearchInput}
  />
);

export default FilterSearch;
