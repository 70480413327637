import {
  type Batch,
  type CatalogueDepartmentSkuCapacity,
  type CatalogueProduct,
  type GetCatalogueSkuCapacitiesResponse,
  type ProductFilters,
} from '@autone/openapi-buying';
import { encodeUrl, type ExtendedCustomBaseQueryType } from '@autone/ui';

import { type BuyingFilters } from '../../../types/filters';
import { type UpdateProduct } from '../../../types/products';
import {
  handleCatalogueDepartmentSkuCapacitiesError,
  updateCatalogueDepartmentSkuCapacities,
} from '../../helpers/products';
import { buyingApi } from '../apis';

export const productsApi = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the get top products endpoint
    getTopProducts: builder.query<
      CatalogueProduct[],
      { batchId: Batch['id']; currencyIso: string }
    >({
      query: ({ batchId, currencyIso }) =>
        encodeUrl({
          url: `batch/{batchId}/catalogue/recommended?currency_iso={currencyIso}`,
          variables: { batchId, currencyIso },
        }),
      providesTags: ['TopProducts'],
      transformResponse: (response: { catalogue: CatalogueProduct[] }) =>
        response.catalogue,
    }),
    getProductFilters: builder.query<
      BuyingFilters,
      { batchId: Batch['id']; buyingCurrencyIso: string }
    >({
      query: ({
        batchId,
        buyingCurrencyIso,
      }: {
        batchId: Batch['id'];
        buyingCurrencyIso: string;
      }) =>
        encodeUrl({
          url: `batch/{batchId}/catalogue/filters?currency_iso={buyingCurrencyIso}`,
          variables: { batchId, buyingCurrencyIso },
        }),
      providesTags: ['ProductFilters'],
      transformResponse: (response: { filters: BuyingFilters }) =>
        response.filters,
    }),
    getProducts: builder.query<
      CatalogueProduct[],
      {
        batchId: Batch['id'];
        filters: ProductFilters;
        currency_iso: string;
      }
    >({
      query: ({ batchId, filters, currency_iso }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/catalogue`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters, currency_iso },
      }),
      providesTags: ['Products'],
      transformResponse: (response: { catalogue: CatalogueProduct[] }) =>
        response.catalogue,
    }),
    updateProducts: builder.mutation<
      Record<never, never>,
      {
        batchId: Batch['id'];
        products: UpdateProduct[];
        currencyIso: string;
        filters: ProductFilters;
      }
    >({
      invalidatesTags: [
        'ProductMismatches',
        'AssortmentFilters',
        'AssortmentMismatches',
        'Review',
      ],
      async queryFn(
        { batchId, products, currencyIso, filters },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<GetCatalogueSkuCapacitiesResponse>
          | ExtendedCustomBaseQueryType<void>,
      ) {
        const patchCatalogueResult = dispatch(
          productsApi.util.updateQueryData(
            'getProducts',
            {
              batchId,
              currency_iso: currencyIso,
              filters,
            },
            (draft) => {
              draft.forEach((cachedProduct) => {
                const productToUpdate = products.find(
                  ({ product_id: productId }) =>
                    productId === cachedProduct.product.id,
                );

                if (!productToUpdate) return;

                if (productToUpdate.selected !== undefined) {
                  cachedProduct.selected = productToUpdate.selected;
                }
                if (productToUpdate.favourited !== undefined) {
                  cachedProduct.favourited = productToUpdate.favourited;
                }
              });
            },
          ),
        );

        const patchTopProductResult = dispatch(
          productsApi.util.updateQueryData(
            'getTopProducts',
            { batchId, currencyIso },
            (draft) => {
              draft.forEach((cachedProduct) => {
                const productToUpdate = products.find(
                  ({ product_id: productId }) =>
                    productId === cachedProduct.product.id,
                );
                if (!productToUpdate) return;

                if (productToUpdate.selected !== undefined) {
                  cachedProduct.selected = productToUpdate.selected;
                }
                if (productToUpdate.favourited !== undefined) {
                  cachedProduct.favourited = productToUpdate.favourited;
                }
              });
            },
          ),
        );

        const response = await (
          customBaseQuery as ExtendedCustomBaseQueryType<void>
        )({
          url: encodeUrl({
            url: `/batch/{batchId}/catalogue`,
            variables: { batchId },
          }),
          method: 'PATCH',
          body: {
            products,
          },
        });

        if (response?.error) {
          // @ts-expect-error
          patchCatalogueResult.undo();
          // @ts-expect-error
          patchTopProductResult.undo();
          return { error: response.error };
        }

        await updateCatalogueDepartmentSkuCapacities({
          dispatch,
          batchId,
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<GetCatalogueSkuCapacitiesResponse>,
        });
        return { data: {}, error: undefined };
      },
    }),
    getCatalogueDepartmentSkuCapacities: builder.query<
      CatalogueDepartmentSkuCapacity[],
      { batchId: string }
    >({
      // @ts-expect-error - I've been unable to properly type this
      async queryFn(
        { batchId },
        _,
        __,
        customBaseQuery: ExtendedCustomBaseQueryType<GetCatalogueSkuCapacitiesResponse>,
      ) {
        const { data, error } = await customBaseQuery({
          url: encodeUrl({
            url: `batch/{batchId}/catalogue/sku-capacity`,
            variables: { batchId },
          }),
          method: 'GET',
        });
        if (error) {
          return handleCatalogueDepartmentSkuCapacitiesError(error);
        }
        return { data: data?.department_sku_capacities, error: null };
      },
      providesTags: ['CatalogueDepartmentSkuCap'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetTopProductsQuery,
  useGetProductFiltersQuery,
  useUpdateProductsMutation,
  useGetCatalogueDepartmentSkuCapacitiesQuery,
} = productsApi;
