import { type Batch } from '@autone/openapi-buying';
import { isKeyInType } from '@autone/ui';
import { useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { STEPS } from '../constants';
import { type StepKeys } from '../constants/steps';
import { useGetBatchQuery, usePatchBatchMutation } from '../redux/services';
import { getBatchIdFromUrl, useGetBuyCurrency } from '../utils';

const getStepNumberFromPathname = (pathname: string, stepKeys: StepKeys) =>
  stepKeys.findIndex(
    (step) => isKeyInType(STEPS, step) && matchPath(pathname, STEPS[step].url),
  );

// this hook checks if the user can access the page based on the current step, it returns a callback for navigating to the last active step
const useCheckStepAccess = () => {
  const location = useLocation();
  const { batchId } = getBatchIdFromUrl();
  const { buyingCurrencyIso } = useGetBuyCurrency();
  const history = useHistory();
  const [patchBatchStep] = usePatchBatchMutation();
  const { data: batchInfo } = useGetBatchQuery({ batchId }, { skip: !batchId });

  // we are only interested in the steps in the header, so we don't redirect on any other routes
  const stepsInHeader = Object.keys(STEPS).filter(
    (step) => isKeyInType(STEPS, step) && STEPS[step].renderInStepper,
  ) as StepKeys;

  const currentStepNumber = getStepNumberFromPathname(
    location.pathname,
    stepsInHeader,
  );

  const maxActiveStepNumber = stepsInHeader.findIndex(
    (step) => step === batchInfo?.step,
  );

  const isUserOnLastActiveStep = currentStepNumber === maxActiveStepNumber;

  const maxStepName = stepsInHeader[maxActiveStepNumber];
  const maxStepUrl = isKeyInType(STEPS, maxStepName)
    ? STEPS[maxStepName].url
    : undefined;

  const maxStepUrlEnriched = maxStepUrl?.replace(':batchId', batchId);

  const redirectUserToLastActiveStep = useCallback(() => {
    if (currentStepNumber > maxActiveStepNumber && maxStepUrlEnriched) {
      history.push({
        pathname: maxStepUrlEnriched,
        search: `?currencyIso=${buyingCurrencyIso}`,
      });
    }
  }, [
    maxStepUrlEnriched,
    currentStepNumber,
    maxActiveStepNumber,
    buyingCurrencyIso,
    history,
  ]);

  const handlePatchBatchStepForUserEdit = (step: Batch['step']) => {
    // whenever the max step is greater than the current step, we reset the step to the current page
    if (maxActiveStepNumber > currentStepNumber) {
      patchBatchStep({
        step,
        batchId,
      });
    }
  };

  const handlePatchBatchStepForConfirmChoices = async (step: Batch['step']) => {
    // we only want to update the step when the user is going beyond the max active step
    if (isUserOnLastActiveStep) {
      const response = await patchBatchStep({
        step,
        batchId,
        hideSnackbar: true,
      });

      if ('error' in response) {
        return { error: response.error };
      }
    }
    return { error: null };
  };

  return {
    isUserOnLastActiveStep,
    stepsInHeader,
    maxActiveStepNumber,
    handlePatchBatchStepForUserEdit,
    handlePatchBatchStepForConfirmChoices,
    redirectUserToLastActiveStep,
  };
};

export default useCheckStepAccess;
