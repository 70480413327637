import {
  type SlackIncomingWebhookPayload,
  type SlackWebhookUrl,
} from './types';

type NotifySlackAppResponse =
  | {
      ok: true;
      response: Response;
    }
  | {
      ok: false;
      error: unknown;
    };

export const notifySlackApp = async <URL extends string>(
  webhookUrl: SlackWebhookUrl<URL>,
  webhookPayload: SlackIncomingWebhookPayload,
): Promise<NotifySlackAppResponse> => {
  /**
   * Slack instructions for Incoming Webhooks says that the request `Content-Type`
   * must be set to `application/json`. However, this does not appear to work when
   * the call is triggered from frontend JS code because Slack is not handling the
   * OPTIONS preflight request for CORS. The `Content-Type` header is not allowed by
   * the `Access-Control-Allow-Headers` header and so omitting it here seems to be
   * the solution.
   *
   * Source:
   * https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce
   */
  try {
    const response = await fetch(webhookUrl, {
      method: 'POST',
      body: JSON.stringify(webhookPayload),
    });
    return { ok: true, response };
  } catch (e) {
    return { ok: false, error: e };
  }
};
